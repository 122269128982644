import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 系统关闭活动
const additionalClosed = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/applyRegister/closed', params)
}

// 申请提交
const additionalCreate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/applyRegister/create', params)
}

// 申请记录
const additionalRecords = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/applyRegister/records', params)
}

export default {
	additionalClosed,
	additionalCreate,
	additionalRecords
}
