<template>
	<div class="Additional">
		<w-navTab titleText="补卡申请"></w-navTab>
		<div class="container-top" @click="$router.push({ name: 'AdditionalList' })">
			<img src="@/assets/img/icon99.jpg" alt="" />
		</div>
		<div class="container">
			<div class="middle-body">
				<div class="head-form">
					<div class="head-form-item" @click="onPopup">
						<div class="item-left">活动名称</div>
						<div class="item-right">
							<div class="item-right-text">{{typeText}}</div>
							<div class="item-right-box"><van-icon name="play" color="#777B81" /></div>
						</div>
					</div>
					<!-- 打卡记录弹窗 -->
					<div class="middle-body-list" v-show="isShow">
						<div class="middle-body-item" v-for="(item,index) in navList" @click="onNavList(item,index)">
							<div class="item-title">{{item.title}}</div>
							<div class="item-text">{{item.activity_finish_date}} {{item.activity_finish_time}}</div>
						</div>
					</div>
					
					<div class="head-form-item " @click="onTime">
						<div class="item-left">结束打卡时间</div>
						<div class="item-right">
							<div class="item-right-text">{{timeText}}</div>
							<div class="item-right-box"><van-icon name="play" color="#777B81" /></div>
						</div>
					</div>
					<div class="head-form-item" >
						<div class="item-left">一起执勤人姓名</div>
						<div class="item-right">
							<input type="text" v-model="verify_name" placeholder="请输入一起执勤人姓名">
						</div>
					</div>
					<div class="head-form-item" >
						<div class="item-left">一起执勤人电话</div>
						<div class="item-right">
							<input type="text" v-model="verify_mobile" placeholder="请输入一起执勤人电话">
						</div>
					</div>
				</div>
				<div class="middle-text">
					<textarea placeholder="请输入内容" v-model="apply_reason"></textarea>
					<div class="middle-footer-left">
					    <div>({{ apply_reason.length }}/100)</div>
					</div>
				</div>
				<div class="uploader">
					<div class="uploader-item" v-for="(vo, index) in picture">
						<img class="uploader-close" src="@/assets/img/uploader_icon_close.png" @click="deletePicture(index)" />
						<img class="uploader-image" :src="vo.url" />
					</div>
					<div class="uploader-item uploader-item-select"><van-uploader :after-read="onSelect" v-show="isShowUploader" upload-icon="plus"></van-uploader></div>
				</div>
			</div>
			<div class="middle-footer-title">补卡说明</div>
			<div class="middle-footer-text">
				1、可补卡日期为活动结束后五天内；
				<br>
				2、请确保提供的信息真实准确，若发现提供虚假信息，将会严厉警告并冻结账号；
				<br>
				3、协助他人作弊将一并受到处罚。
			</div>
			<div class="create-footer"><div class="footer-button" @click="onSubmit">提交任务</div></div>
			<van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
				<van-datetime-picker v-model="currentDate" type="datetime" title="选择结束打卡时间" :min-date="minDate" :max-date="maxDate" @confirm="confirm" @cancel="show = false" />
			</van-popup>
		</div>
	</div>
</template>

<script>
import UploadApi from '@/api/upload';
import VantVendor from '@/vendor/vant';
import Moment from '@/vendor/moment';
import Event from '@/store/event';
import ClueReport from '@/api/clueReport';
import Additional from '@/api/additional';
import ValidatorVendor from '@/vendor/validator';
export default {
	name: 'Additional',
	data() {
		return {
			picture: [],
			show: false,
			isShow: false,
			isShowUploader: true,
			minDate: new Date(2023, 0, 1),
			maxDate: new Date(2030, 12, 31),
			currentDate: new Date(),
			navList: [],
			listText: "",
			timeText: '请选择你离开的时间',
			typeText: "请选择活动",
			register_id: "",
			finish_time: "",
			verify_name: "",
			verify_mobile: "",
			apply_reason: "",
			validator: null,
		};
	},
	watch: {
		picture(newVal, oldVal) {
			this.isShowUploader = newVal.length < 2;
		},
		content(newVal, oldVal) {
		    if (newVal.length > 100) {
		        this.apply_reason = this.apply_reason.substring(0, 100);
		    }
		},
	},
	created() {
		this.onValidator()
		this.getNavList()
	},
	methods: {
		onPopup(){
			if(this.navList == ""){
				VantVendor.Toast('未查询到要补卡的活动');
			}else{
				this.isShow = true
			}
		},
		getNavList(){
			Additional.additionalClosed()
				.then(result=>{
					this.navList = result.data
				},error=>{
					VantVendor.Toast.clear();
				})
		},
		onNavList(item,index){
			this.isShow = false
			this.typeText = item.title
			let time = Moment(item.begin_time * 1000).format('YYYY-MM-DD')
			this.listText = time + ' ' + item.activity_finish_time
			let list = Moment.utc(this.listText).toDate()
			let list2 = Moment(list).utcOffset(-8)
			this.maxDate = list2._d
			this.register_id = item.register_id
		},
		confirm(value){
			this.timeText = Moment(value).format('YYYY-MM-DD HH:mm');
			this.finish_time = Moment(value).unix() 
			this.show = false
		},
		// 选择时间弹窗判断是否选择活动
		onTime(){
			if(this.register_id == ''){
				VantVendor.Toast('请先选择活动');
			}else{
				this.show = true
			}
		},
		onSelect(selected) {
			VantVendor.Toast.loading('上传中');
			UploadApi.image(selected.file)
				.then(result => {
					this.picture.push({
						url: result.data.fileUrl,
						path: result.data.filePath
					});
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				})
				.finally(() => {
					VantVendor.Toast.clear();
				});
		},
		deletePicture(index) {
			this.picture.splice(index, 1);
		},
		onValidator(){
			this.validator = new ValidatorVendor({
				rules: {
					register_id: {
						required: true,
					},
					finish_time: {
						required: true,
					},
					verify_name: {
						required: true,
					},
					verify_mobile: {
						required: true,
						mobile: true
					},
					apply_reason: {
						required: true
					},
					apply_photo: {
						required: true
					}
				},
				messages: {
					register_id: {
						required: '活动不能为空'
					},
					finish_time: {
						required: '结束时间不能为空'
					},
					verify_name: {
						required: '一起执勤人姓名不能为空'
					},
					verify_mobile: {
						mobile: '手机号格式错误',
						required: '一起执勤人电话不能为空'
					},
					apply_reason: {
						required: '补卡原因不能为空'
					},
					apply_photo: {
						required: '图片不能为空'
					}
				}
			});
		},
		onSubmit() {
			let params = {
				register_id: this.register_id,
				finish_time: this.finish_time,
				verify_name: this.verify_name,
				verify_mobile: this.verify_mobile,
				apply_reason: this.apply_reason,
				apply_photo: this.picture.map(item => item.path).join()
			};
			if (!this.validator.checkData(params)) {
				return;
			}
			Additional.additionalCreate(params)
				.then(result=>{
					VantVendor.Toast.success("提交成功");
					this.$router.push('AdditionalList')
				},error=>{
					VantVendor.Toast(error.msg);
				})
		},
	}
};
</script>

<style scoped lang="less">
.Additional {
	background: #F5F6F8;
	min-height: 100vh;
	position: relative;
	padding-bottom: 55px;
	.container-top{
		width: 100%;
		height: 200px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.container{
		padding: 10px;
		box-sizing: border-box;
		.header-top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 8px;
			.header-top-title{
				font-weight: bold;
				font-size: 17px;
				line-height: 20px;
				color: #1C1D1D;
			}
			.header-top-info{
				font-size: 14px;
				line-height: 20px;
				color: #1C1D1D;
			}
		}
		.head-form {
			position: relative;
			.head-form-item {
				background: #fff;
				padding: 13px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #F5F6F8;
				.item-left {
					font-size: 15px;
					line-height: 22px;
					color: #44484e;
				}
				.item-right {
					font-size: 15px;
					color: #1c1d1d;
					display: flex;
					align-items: center;
					
					input{
						text-align: right;
						border: 0;
					}
					textarea{
						min-width: 240px;
						border: 0;
						height: 18px;
						text-align: right;
					}
					
					.item-right-text {
						font-size: 15px;
						color: #1c1d1d;
						max-width: 180px;
						flex-wrap: wrap;
					}
					.item-right-box {
						font-size: 15px;
						color: #3377FF;
						margin-left: 5px;
					}
					.item-right-box-picture{
						width: 20px;
						height: 20px;
						margin-left: 4px;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
			.head-box{
				padding-bottom: 22px;
			}
			.head-form-item-text{
				font-size: 14px;
				line-height: 20px;
				color: #777;
				margin-top: -20px;
			}
			.middle-body-list {
				width: 355px;
				border: 1px solid #f2f2f2;
				border-top: 0;
				border-radius: 0px 0px 4px 4px;
				background-color: #F5F6F8;
				position: absolute;
				z-index: 10;
				left: -10px;
				box-sizing: border-box;
			
				.middle-body-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 12px 8px;
					border-bottom: 1px dashed #ddd;
			
					&.middle-body-item {
						border-bottom: 0;
					}
			
					.item-title {
						font-size: 14px;
						line-height: 20px;
						color: #222;
						width: 190px;
						max-width: 190px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
			
					.item-text {
						font-size: 14px;
						line-height: 20px;
						color: #777;
					}
				}
			}
		}
		
		.head-top {
			padding: 10px;
			padding-bottom: 0;
			background-color: #fff;
			border-radius: 6px;
			.head-middle {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 12px;
				.head-top-left {
					.head-top-text {
						font-size: 14px;
						line-height: 28px;
						color: #666;
					}
					.head-top-title {
						font-size: 18px;
						line-height: 26px;
						color: #000;
					}
				}
				.head-top-right {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					.right-text {
						font-size: 14px;
						line-height: 26px;
						color: #666;
					}
				}
			}
		
			.head-top-bottom {
				margin-top: -20px;
				height: 13px;
				.head-top-bottom-picture {
					width: 100%;
					height: 2px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
		
		.head-empty-address {
			width: 100%;
			height: 60px;
			line-height: 60px;
			background-color: #fff;
			border-radius: 6px;
			display: flex;
			justify-content: center;
			align-items: center;
		
			.head-empty-address-text {
				margin-left: 5px;
				font-size: 16px;
				color: #666;
			}
		
			.van-icon {
				font-size: 18px;
				color: #666;
			}
		}
		.item-footer{
			margin-top: 8px;
			display: flex;
			align-items: center;
			.footer-select{
				margin-right: 16px;
				font-size: 14px;
				line-height: 20px;
				color: #777B81;
				/deep/.van-icon{
					border-radius: 2px;
					color: #fff;
				}
			}
		}
		
		.middle-body{
			background-color: #fff;
			border-radius: 6px;
			padding: 10px;
			padding-top: 0;
			.middle-text {
				width: 100%;
				height: 100%;
				background-color: #fff;
				box-sizing: border-box;
				border-radius: 6px;
				margin-top: 10px;
				position: relative;
			
				textarea {
					width: 100%;
					height: 124px;
					background-color: #fff;
					border: 0;
					padding: 0;
					font-size: 16px;
				}
				
				.middle-footer-left {
				    position: absolute;
				    right: 10px;
				    bottom: 0;
				    font-size: 14px;
				    line-height: 26px;
				    color: #666;
				}
			}
			.uploader {
				display: flex;
				margin-top: 16px;
					
				.uploader-item {
					position: relative;
					margin-right: 12px;
					
					&:last-child {
						margin-right: 0;
					}
					
					.uploader-close {
						position: absolute;
						top: 5px;
						right: 5px;
						width: 16px;
						height: 16px;
					}
					
					.uploader-image {
						width: 75px;
						height: 75px;
						object-fit: cover;
						border-radius: 6px;
					}
				}
					
				.uploader-item-select {
					position: relative;
					
					/deep/ .van-uploader__upload {
						width: 75px;
						height: 75px;
						margin: 0;
						border-radius: 6px;
						background-color: #f2f2f2;
					
						.van-icon {
							font-size: 30px;
							color: #aaa;
						}
					}
				}
			}
		}
		.middle-footer-title{
			font-weight: bold;
			font-size: 17px;
			line-height: 20px;
			color: #222;
			text-align: center;
			padding: 16px 0 8px 0;
		}
		.middle-footer-text{
			font-size: 14px;
			line-height: 25px;
			color: #777;
		}
		.create-footer {
			position: fixed;
			bottom: 12px;
			left: 0;
			padding: 0 8px;
			width: 100%;
			box-sizing: border-box;
			z-index: 10;
			.footer-button {
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				padding: 11px;
				color: #fff;
				background: linear-gradient(92.48deg, #3377FF 0%, #3377FF 100%);
				box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
				border-radius: 4px;
			}
		}
	}

}
</style>
